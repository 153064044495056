/**
 *
 * CSS File
 * Copyright Marktplatz GmbH - Agentur für Web & App <info@marktplatz-agentur.de>
 *
 */

@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  font-family: 'Roboto', sans-serif;
}

html,
body,
.LoggedInArea {
  height: 100%;
}

body.u-noScroll {
  overflow: hidden;
  max-height: 100vh;
}

body.u-noScroll::after {
  content: '';
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1;
}

.app {
  overflow: hidden;
}

button {
  cursor: pointer;
}

hr {
  margin: 20px 0;
  border: none;
  border-top: 2px solid #3263aa;
}

.ContentArea {
  min-height: calc(100% - 134px);
  margin-top: 100px;
  margin-left: 0px;
  background: #fff;
  position: relative;
}

.Loader-Wrapper {
  width: 100%;
  text-align: center;
}

.Loader-text {
  color: #3263aa;
  font-weight: 700;
}

.filepond--wrapper {
  width: 100%;
}

.filepond--panel-root {
  border-radius: 5px;
  background: rgb(243, 244, 245);
}

/* ==================== HEADINGS ==================== */
h2 {
  font-size: 21px;
  color: #282e41;
  font-weight: 400;
  margin-bottom: 15px;
}

/* ==================== ERROR MODAL ==================== */

.Alert {
  position: absolute;
  z-index: 99999;
  top: 0;
  right: 0;
  width: 300px;
  height: 400px;
  padding: 25px 40px 35px 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  transform-origin: 100% 0;
  transform: scale(0.00001);
  transition: transform 250ms ease;
}

.Alert-Top {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Alert--is-Active {
  transform: scale(1);
}

.Alert-Title {
  font-size: 25px;
  margin-top: 20px;
  position: relative;
  margin-bottom: 40px;
}

.Alert-Title::after {
  content: '';
  width: 160px;
  height: 6px;
  position: absolute;
  left: 50%;
  bottom: -15px;
  border-radius: 5px;
  transform: translateX(-50%);
}

.Alert-Text {
  font-size: 16px;
  text-align: center;
  margin-bottom: 40px;
}

.Alert-Button {
  width: 100%;
  border: 2px solid;
  border-radius: 14px;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 0;
  background: transparent;
  cursor: pointer;
  transition: all 250ms ease;
}

.Alert--warning {
  background-color: #ffee8e;
  color: #937700;
}

.Alert--warning .Alert-Title::after {
  background: rgba(191, 159, 23, 0.4);
}

.Alert--warning .Alert-Button {
  border-color: #937700;
  color: #937700;
}

.Alert--warning .Alert-Button:hover {
  background-color: #937700;
  color: #ffee8e;
}

.Alert--error {
  background-color: #e40521;
  color: #ffffff;
}

.Alert--error .Alert-Title::after {
  background: rgba(255, 255, 255, 0.6);
}

.Alert--error .Alert-Button {
  border-color: #ffffff;
  color: #ffffff;
}

.Alert--error .Alert-Button:hover {
  background-color: #ffffff;
  color: #e40521;
}

/* ==================== FIELDS ==================== */

.field {
  color: #282e41;
  padding: 30px;
  font-size: 16px;
  border: 0;
  border-radius: 0;
}

.field::placeholder {
  color: rgba(80, 97, 131, 0.8);
}

.fieldTextarea-Wrapper {
  width: 100%;
  position: relative;
}

.fieldTextarea {
  width: 100%;
  resize: vertical;
  max-height: 300px;
  height: 200px;
}

.fieldTextareaCounter {
  position: absolute;
  right: 20px;
  bottom: 10px;
  pointer-events: none;
  color: #282e41;
  transform: scale(0.000001);
  transition: transform 250ms ease;
}

.fieldTextareaCounter.is-Active {
  transform: scale(1);
}

.fieldTextareaCounter.is-Red {
  color: #e40521;
}

.field-border-bottom {
  border-bottom: 6px solid #eaeef2;
}

/* ================== INPUTS ================== */
.Input-wrapper {
  width: 100%;
}

.Input,
.Datepicker {
  position: relative;
  width: 100%;
  color: #282e41;
  font-size: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.Input-info {
  display: inline-block;
  width: 25px;
  text-align: center;
}
.Input-info .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  margin-top: -30px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.Input-info:hover .tooltiptext {
  visibility: visible;
}

.Input-field,
.DayPickerInput input,
.Datepicker-disabled {
  display: block;
  width: 100%;
  padding: 10px;
  font-weight: 400;
  font-size: 16px;
  background: white;
  border: 1px solid #9fb4cb;
  border-radius: 5px;
  outline: 0;
  transition: all 0.3s ease-in-out;
  margin-top: 5px;
}

.Datepicker-disabled {
  color: gray;
}

textarea.Input-field {
  min-height: 100px;
  resize: vertical;
}

textarea.Input-field.counselin-concern {
  min-height: 659px;
  resize: vertical;
}

.Input-error,
.Datepicker-error,
.Dropdown-error {
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 10px;
  padding: 5px 15px;
  font-size: 12px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
  background: #e40521;
  z-index: 3;
  color: #ffffff;
  transform: translateY(40%);
}

.Input-passwordRules {
  font-size: 12px;
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
}

.Login-Form .Input-passwordRules {
  width: 100%;
  margin-left: 0;
  position: relative;
  display: flex;
  background: transparent;
  border-radius: 10px;
  padding: 10px 20px;
}

.Input-passwordRule {
  color: #282e41;
  flex: 1 0 50%;
}

.Input-passwordRule-passed {
  color: #a6d35d;
}

.Input-passwordShowButton .Input-field {
  padding-left: 85px;
}

.Input-showPasswordButton {
  position: absolute;
  left: 0;
  top: 53px;
  transform: translateY(-50%);
  height: 51px;
  margin-top: 2px;
  border: 0;
  background: transparent;
  padding: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 1px solid rgba(80, 97, 131, 0.55);
  max-width: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Input-showPasswordButton-text {
  margin: auto;
}

.Input-wrapper:not(.Input--fullWidth),
.Dropdown:not(.Input--fullWidth),
.Datepicker:not(.Input--fullWidth),
.Switch:not(.Input--fullWidth) {
  max-width: calc(50% - 30px);
}
/* ================== LOGIN FORM POP ================== */
#LoginFormPop {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
}
.Login-form-pop-room {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.Login-form-pop {
  background: #282d40;
  border-radius: 10px;
  color: #fff;
  height: 100%;
  left: 50%;
  max-height: 400px;
  width: auto;
  padding: 1rem;
  position: absolute;
  top: 30%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.Login-form-pop h2 {
  display: flex;
  color: #ffffff;
  font-size: 20px;
  margin: 10px 0 20px;
  font-weight: 700;
  justify-content: center;
}
.Login-form-pop p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  margin-bottom: 1rem;
}
.Login-Form-Pop-Submit {
  color: #ffffff;
  background: #a4dd83;
  font-size: 16px;
  font-weight: 700;
  border: 0;
  text-decoration: none;
  cursor: pointer;
  padding: 30px;
  transition: all 250ms ease;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 15px 15px;
}
/* ================== DATE PICKER ================== */
.DayPickerInput {
  display: block;
  width: 100%;
}
.DayPickerInput-OverlayWrapper {
  z-index: 9999;
}
/* ================== SWITCH ================== */
.Switch {
  margin: 0 0 15px 0;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.Switch-input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
}
.Switch-title {
  display: block;
  color: #282e41;
  font-size: 16px;
  min-width: 250px;
  max-width: 100%;
}
.Switch-info .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  margin-top: -30px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.Switch-info:hover .tooltiptext {
  visibility: visible;
}

.Switch-description {
  display: block;
  font-weight: 400;
  color: rgba(80, 97, 131, 0.6);
}
.Switch-label {
  display: block;
  padding: 15px 0;
  user-select: none;
  cursor: pointer;
}

.Switch-label-state {
  display: inline-block;
  position: absolute;
  height: 16px;
  padding-left: 10px;
}

.Switch-switch {
  position: relative;
  display: inline-block;
  z-index: -1;
  width: 40px;
  height: 20px;
  border: 1px solid #9fb4cb;
  border-radius: 50px;
  background-color: #ffffff;
  transition: all 200ms ease;
}
.Switch-switch::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #c1c1c1;
  transform: translate(2px, -50%);
  transition: transform 200ms cubic-bezier(0.65, 0.38, 0.29, 1.19);
}
.Switch-input:checked ~ .Switch-switch {
  background-color: #282e41;
}
.Switch-input:checked ~ .Switch-switch::after {
  transform: translate(20px, -50%);
}

/* ==================== DROPDOWN ==================== */
.Dropdown {
  font-size: 16px;
  color: #282e41;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 15px;
}

.Dropdown-label {
  color: #282e41;
  font-size: 16px;
}

.Dropdown-info {
  display: inline-block;
  width: 25px;
  text-align: center;
}
.Dropdown-info .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  margin-top: -30px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.Dropdown-info:hover .tooltiptext {
  visibility: visible;
}

.Dropdown-input {
  width: 100%;
}

.Dropdown__control.Dropdown__control,
.Dropdown__control--is-focused {
  padding: 8px 5px;
  font-weight: 400;
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #9fb4cb;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  margin-top: 5px;
  outline: none;
  box-shadow: none;
}

.Dropdown__control:hover.Dropdown__control:hover {
  cursor: pointer;
}

.LoginDropdown__control.LoginDropdown__control {
  border: 1px solid #282e41;
  box-shadow: 0;
  background: transparent;
  border-radius: 10px;
  padding: 15px 10px;
}

.LoginDropdown__control.LoginDropdown__control::after {
  content: '\f0d7';
  height: 20px;
  width: 20px;
  position: absolute;
  right: 15px;
  top: 49%;
  color: #282e41;
  transform: translateY(-50%);
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  font-size: 23px;
}

.LoginDropdown__single-value.LoginDropdown__single-value,
.LoginDropdown__placeholder.LoginDropdown__placeholder {
  font-size: 16px;
  color: #282e41;
}

.LoginDropdown__indicator svg,
.LoginDropdown__indicator-separator  {
  display: none;
}

.Dropdown:active,
.Dropdown:focus {
  border-color: #282e41;
}

.Dropdown-Caret {
  position: absolute;
  top: 70%;
  right: 10px;
  transform: translateY(-50%);
  color: #4b4f5d;
  pointer-events: none;
}

.Dropdown--is-disabled .Dropdown-Caret {
  display: none;
}

.Toolbar .Dropdown-Caret {
  top: 55%;
}

.LoginDropdown__indicators.LoginDropdown__indicators,
.Dropdown__indicator svg,
.Dropdown__indicator-separator {
  display: none;
}

.Dropdown-w100p {
  width: 100%;
}

.Slash {
  display: inline-block;
  margin: 0 20px;
}

/* ==================== BUTTONS ==================== */

.Button {
  -webkit-appearance: none;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  padding: 10px 20px;
  transition: all 250ms ease;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #272d40;
  margin: 5px;
  border: none;
  outline: none;
}

.Button--big {
  width: 100%;
}

.Button--small {
  font-size: 14px;
}

.Button--outline {
  border: 2px solid #272d40;
  border-radius: 5px;
}

.Button--primary {
  background: #272d40;
  color: white;
  border-color: #272d40;
  border-radius: 10px;
}

.Button--default {
  background: transparent;
  color: #272d40;
  border-color: #272d40;
}

.Button--danger {
  background: transparent;
  color: #e40521;
  border-color: #e40521;
}

.Button--warning {
  background-color: #ffc107;
  /* background: transparent; */
  color: white;
  /* border-color: #937700; */
  border-color: #ffc107;
  border-radius: 10px;
}

.Button--loading {
  position: relative;
  padding-left: 60px;
}

.Button-spinner {
  height: 1em;
  margin-right: 10px;
}
.Button-spinnerPath {
  stroke: #fff;
  stroke-linecap: round;
  animation: dashButtonSpinnerPath 1.5s ease-in-out infinite;
}

@keyframes dashButtonSpinnerPath {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.Button-icon {
  margin-right: 10px;
}

/* ==================== HEADERBAR-BUTTONS ==================== */

.HeaderbarButton .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  margin-top: -30px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

@media screen and (max-width: 1500px) {
  .HeaderbarButton:hover .tooltiptext {
    visibility: visible;
  }

  .HeaderbarButton .button-text {
    display: none;
  }
}

/* ==================== HEADERBAR ==================== */
.Headerbar {
  position: relative;
  min-height: 50px;
  margin-left: 115px;
  background: #272d40;
  color: white;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  z-index: 100;
}

.Headerbar.sticky {
  position: sticky;
  top: 100px;
  width: calc(100% - 115px);
}

.Headerbar-breadcrumbs {
  flex-grow: 1;
  padding: 5px 10px;
}

.Headerbar-breadcrumbs a {
  color: white;
  text-decoration: underline;
}

.Headerbar-buttons {
  flex-grow: 1;
  text-align: right;
}

.Headerbar-buttons .Button {
  color: #fff;
  font-size: small;
  margin: 0;
  padding: 10px;
  background: transparent;
}

.Headerbar-buttons .Button.trash {
  background: #e4002c;
  padding-right: 0;
  height: 50px;
  width: 50px;
}

.Page-Content {
  padding: 10px;
  height: calc(100vh - 150px);
  overflow-y: scroll;
}

/* ==================== TOOLBAR ==================== */
.Toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.Toolbar-buttons {
  margin-left: auto;
}

.Toolbar .Dropdown {
  min-width: 250px;
  margin-left: 20px;
  margin-bottom: 0;
}

/* ==================== SEARCHBAR ==================== */

.Searchbar {
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: #efefef;
  padding-left: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Searchbar-icon {
  color: #282e41;
}

.Searchbar-Field {
  border-radius: 10px;
  height: 100%;
  min-width: 390px;
  max-width: 390px;
  font-size: 16px;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  padding: 10px 20px;
  color: #282e41;
  outline: 0;
}

.Searchbar-Field::placeholder {
  font-size: 16px;
  color: #282e41;
}

.Searchbar-wrapper--small {
  margin-left: 5px;
}

.Searchbar-wrapper--small .Searchbar {
  padding-left: 0;
}

.Searchbar-wrapper--small .Searchbar-Field {
  min-width: 200px;
  max-width: 200px;
}

.Searchbar-Results-Amount {
  font-size: 16px;
  color: #282e41;
  font-weight: 700;
}

/* ==================== Buttonbar ==================== */
.Buttonbar-bottom {
  text-align: right;
  margin-bottom: 40px;
}
.Buttonbar-flex {
  display: flex;
  justify-content: flex-end;
}
/* ==================== SELECT ==================== */

.select {
  -webkit-appearance: none;
  color: #282e41;
  border: 1px solid #282e41;
  padding: 20px;
  border-radius: 10px;
  font-size: 16px;
}

/* ==================== LINK ==================== */

.Link {
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #282e41;
  font-size: 16px;
}

.Link:hover {
  cursor: pointer;
}

.Link--underlined {
  text-decoration: underline;
}

/* ==================== RADIUS ==================== */

.radius {
  border-radius: 10px;
}

.radius-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.radius-under {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* ==================== LOGIN ==================== */

.Login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  background: rgb(244, 244, 245);
  background: linear-gradient(
    180deg,
    rgba(244, 244, 245, 1) 0%,
    rgba(212, 213, 217, 1) 80%,
    rgba(163, 166, 180, 1) 100%
  );
}

.Login-Form-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Login-Form {
  width: 625px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 36px rgba(22, 65, 148, 0.1);
}

.Login-Form-logo {
  width: 250px;
  position: absolute;
  top: -150px;
  left: 50%;
  transform: translateX(-50%);
}

.Login-Form-box-contact .Login-Form-logo {
  top: -150px;
}

.Login-Form-title {
  color: #282e41;
  font-size: 26px;
  margin-bottom: 25px;
  text-align: center;
}

.Login-Form-title--changePassword {
  margin-bottom: 0;
}

.Login-Form-description {
  opacity: 0.6;
  margin-left: 30px;
  margin-bottom: 25px;
}

.Login-Form-actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.Login-Form-actions > * + * {
  margin-left: 20px;
}

.Login-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 30px 40px;
}

.Login-submit {
  color: #ffffff;
  background: #282e41;
  -webkit-appearance: none;
  font-size: 16px;
  font-weight: 700;
  border: 0;
  text-decoration: none;
  cursor: pointer;
  padding: 30px;
  transition: all 250ms ease;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 15px 15px;
}

.Login-submit:disabled {
  filter: grayscale(40%);
  cursor: not-allowed;
}

.Logout-button {
  -webkit-appearance: none;
  font-size: 16px;
  font-weight: 700;
  border: 0;
  text-decoration: none;
  cursor: pointer;
  background: transparent;
  padding: 30px;
  color: #272d40;
}

.Footer-link {
  margin-right: 20px;
}

.Footer-version {
  -webkit-appearance: none;
  border: 0;
  background: 0;
  font-size: 16px;
  color: #282e41;
}

/* ==================== LOGIN OFFICE ==================== */

.Login-Form--office {
  padding: 20px 30px 40px 30px;
  background: #ffffff;
}

.Login-Form--office .Login-Form-title {
  width: 100%;
  text-align: center;
  font-size: 25px;
  margin: 0;
  margin-bottom: 35px;
  position: relative;
}

.Login-Form--office .Login-Form-title::after {
  content: '';
  background: #eaeef2;
  width: 160px;
  height: 6px;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
}

/* ==================== NAVIGATION ==================== */

.Navigation {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;
}

.Navigation-Left {
  position: fixed;
  left: 0;
  top: 0;
  /* width: 115px; */
  width: 0;
  height: 100%;
}

.Navigation-Left.is-Active .Navigation-Sidenav {
  width: 250px;
  box-shadow: 0 0 3px gray;
}

.Navigation-Left-Top {
  height: 100px;
  background: #f4f4f5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 115px;
  border-right: 1px solid #272d40;
}

.Navigation-FirstLevelMenu-Toggle {
  font-size: 2em;
  border: none;
  outline: none;
}

.Navigation-Left-Top-2 {
  display: flex;
  height: 50px;
  background: #272d40;
  justify-content: center;
  align-items: center;
  width: 115px;
  border-right: 1px solid white;
}

.Navigation-Left-Top-2 .Navigation-Home {
  cursor: pointer;
  color: #ffffff;
}

.Navigation-Sidenav {
  width: 0px;
  overflow: hidden;
  height: 100%;
  background: #d4d5d9;
  transition: width 400ms ease-in-out;
}

.Navigation-FirstLevelMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100px;
  background: transparent;
  border: 0;
  text-decoration: none;
  color: #272d40;
  cursor: pointer;
  white-space: nowrap;
}

.Navigation-SecondLevelmenu {
  display: flex;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  transition: all 250ms ease-in-out;
  transition-delay: 200ms;
  overflow: hidden;
}

.Navigation-SecondLevelmenu.is-Active {
  opacity: 1;
  pointer-events: unset;
}

.Navigation-SecondLevelmenu-Link {
  display: block;
  width: 100%;
  color: #272d40;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  padding: 15px 10px;
  cursor: pointer;
  white-space: nowrap;
  transition: all 250ms ease-in-out;
}

.Navigation-SecondLevelmenu-Link.is-Active {
  color: #fff;
  background: #272d40;
}

.Navigation-SecondLevelmenu-Link:hover {
  color: #fff;
  background: #272d40;
}

.Navigation-Top {
  height: 100px;
  justify-content: space-between;
  width: calc(100% - 115px);
}

.Navigation-Top a {
  align-self: center;
}

.Navigation-Top-1 {
  width: 100%;
  height: 100px;
  font-size: 16px;
  padding: 25px;
  background: #f4f4f5;
  color: #272d40;
  display: flex;
  align-items: center;
}

.Navigation-Top-Logo {
  height: 100%;
  flex-grow: 11;
}

.Navigation-Top a {
  height: 100%;
  padding: 5px;
}

.Navigation-Top img {
  height: 100%;
}

.Navigaiton-Top-Slogan {
  margin-left: 160px;
}

.Navigation-Search {
  margin-left: 50px;
  display: flex;
  align-items: center;
  width: 150px;
  padding-left: 15px;
  border-radius: 90px;
  height: 50px;
  border: 2px solid #ffffff;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.21);
  background: rgba(239, 239, 239, 1);
  transition: width 500ms ease-in-out;
}

.Navigation-Search:focus-within {
  width: 450px;
}

.Navigation-Search-Icon {
  color: rgba(80, 97, 131, 1);
  height: 20px;
  width: auto;
}

.Navigation-Search-Field {
  color: rgba(80, 97, 131, 1);
  border: 0;
  background: transparent;
  height: 100%;
  width: 100%;
  border-radius: 90px;
  padding-left: 10px;
  font-size: 16px;
  outline: 0;
}

.Navigation-User {
  display: flex;
  align-items: center;
}

.Navigation-User-Text {
  font-weight: 500;
}

.Navigation-User-Image-Box {
  height: 100px;
  width: 100px;
  margin-right: 20px;
  position: relative;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
}

.Navigation-User-Image-Wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}

.Navigation-User-Image {
  height: 100%;
}

.Navigation-User-Menu-Button {
  color: #282e41;
  background: #ffffff;
  border: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  right: -5px;
  bottom: -5px;
  cursor: pointer;
}

.Navigation-User-Menu-Button-Icon.Navigation-User-Menu-Button-Icon {
  height: 20px;
  width: auto;
}

.Navigation-User-Menu {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 180px;
  position: absolute;
  right: -5px;
  bottom: 25px;
  transform: translateY(100%) scale(0.000000001);
  transform-origin: 100% 0;
  border-radius: 15px;
  padding: 10px;
  text-align: right;
  align-items: flex-end;
  box-shadow: 0 8px 6px rgba(80, 97, 131, 0.36);
  transition: transform 300ms ease-in-out;
}

.Navigation-User-Menu.is-Active {
  transform: translateY(100%) scale(1);
}

.Navigation-User-Menu-Close {
  display: block;
  width: 20px;
  height: 20px;
  border: 0;
  background: transparent;
  color: #282e41;
  cursor: pointer;
  font-size: 19px;
}

.Navigation-User-Menu-Close-Icon {
  width: auto;
  height: 20px;
}

.Navigation-User-Menu-Link {
  color: #282e41;
  text-decoration: none;
  padding: 5px 0;
}

.Navigation-Info {
  display: flex;
  align-items: center;
}

.Navigation-Info-Icon {
  margin-right: 15px;
}

/* ==================== SELECT ==================== */

.Selectbox-wrapper {
  position: relative;
  width: 200px;
}

.Selectbox {
  padding: 17px 20px;
  display: inline-block;
  width: 500px;
  -webkit-appearance: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: rgba(80, 97, 131, 0.75);
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 5px;
  border: 0;
}

.Selectbox-wrapper--small {
  display: inline-block;
}

.Selectbox-wrapper--small .Selectbox {
  width: 200px;
  padding: 10px;
}

.Selectbox-arrow {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11px 7px 0 7px;
  border-color: #4e85bf transparent transparent transparent;
  pointer-events: none;
}

/* ==================== FILE ==================== */

.File-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.File-image-picture {
  max-width: 100%;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

.File-preview {
  position: relative;
}

.File-preview::after {
  transition: all 500ms ease;
  content: '\f019';
  font-family: 'Font Awesome 5 Pro';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 15px);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  font-size: 200px;
  font-weight: 300;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.File-preview-noDownload::after {
  content: none;
}

.File-icon {
  font-size: 200px;
}

.File-preview:hover::after {
  opacity: 1;
  cursor: pointer;
}

/* ==================== DIENSTPLAN ==================== */
.Calendar {
  border-collapse: collapse;
  padding: 0;
  margin: 0;
  background: #fff;
  margin-right: 25px;
}

.Calendar th,
.Calendar td {
  text-align: center;
  border: 1px solid #ffffff;
  color: #000000;
}

.Calendar th {
  background: #ebebeb;
  height: 30px;
  line-height: 30px;
  width: 50px;
}

.Calendar .month-header th {
  background: #282e41;
  border: none;
  color: #ffffff;
  height: 40px;
  line-height: 40px;
}

.Calendar .nav .nav-inner {
  cursor: pointer;
  border: 0;
  background: none transparent;
}

.Calendar .Day {
  cursor: pointer;
}

.Calendar .Day .Day-inner {
  outline: none;
  background: #f2f2f2;
  cursor: pointer;
  display: inline-block;
  padding: 0;
  font-size: 16px;
  height: 30px;
  line-height: 30px;
  width: 50px;
  border: none;
}

.Calendar .Day.today .Day-inner {
  background: #ffffff;
  box-shadow: inset 0 0 0 3px #e40521;
  color: #e40521 !important;
}

.Calendar .Day.green .Day-inner {
  background: #a6d35d;
  color: #ffffff;
}

.Calendar .Day.blue .Day-inner {
  background: #282e41;
  color: #ffffff;
}

.Calendar .Day.selected .Day-inner {
  background: none;
}

.Calendar .Day.other-month .Day-inner {
  color: rgba(0, 0, 0, 0.2) !important;
}

.DutySchedule-content {
  display: flex;
  justify-content: center;
  padding: 25px 0;
}

.DutySchedule-nextDuty {
  margin-bottom: 25px;
  font-size: 20px;
}

.DutySchedule-nextHolidays {
  font-size: 20px;
}

.DutySchedule-nextHolidays b {
  color: #a6d35d;
}

/* ==================== WEATHER ==================== */

.Weather {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.Weather-Title {
  font-size: 24px;
  font-weight: 600;
}

.Weather-Bottom {
  text-align: center;
}

.Weather-Unit {
  margin: 7.5px 0;
}

.Weather-Unit:first-of-type {
  margin: 0 0 7.5px 0;
}

.Weather-Unit:last-of-type {
  margin: 7.5px 0 0 0;
}

.Weather-Unit-big {
  font-weight: 600;
}

/* ==================== QUOTE ==================== */

.Quote {
  position: relative;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.Quote-Icon {
  display: block;
  color: #d9dde2;
  margin: 15px 0;
}

.Quote-Icon--Top {
  width: 100%;
  text-align: right;
}

.Quote-Icon--Bottom {
  position: absolute;
  bottom: -15px;
  width: 100%;
  background: #ffffff;
}

.Quote-Text {
  font-size: 16px;
  line-height: 28px;
  max-width: 230px;
  margin: 0 auto;
}

/* ==================== TIME ==================== */

.Time-Body {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 50px;
}

.Time-Body-Time {
  font-weight: 700;
  margin-bottom: 10px;
}

.Time-Body-Date {
  font-size: 26px;
}

/* ==================== MAGAZINE ==================== */
.MagazineWidget {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: inherit;
  border-radius: inherit;
  overflow: hidden;
  z-index: 1;
}

.MagazineWidget-item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-size: cover;
  background-position: center;
}

.MagazineWidget-itemDetails {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px 25px;
  background: rgba(78, 133, 191, 0.87);
  color: #ffffff;
}

.MagazineWidget-itemDescription {
  min-height: 2.5em;
}

.MagazineWidget-itemPublisher {
  position: absolute;
  top: 0;
  right: 15px;
  transform: translateY(-80%);
}

/* ==================== NEWS ==================== */
.NewsWidget-news {
  display: flex;
  align-items: center;
  min-height: 65px;
  padding: 10px 0;
  border-bottom: 1px solid rgba(80, 97, 131, 0.3);
  text-decoration: none;
}

.NewsWidget-newsPublisher {
  min-width: 80px;
  max-width: 80px;
  text-align: center;
  font-size: 12px;
  color: #282e41;
  font-weight: 700;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid;
}

.NewsWidget-newsPublisher span {
  display: block;
}

.NewsWidget-newsDetails {
  flex: 1;
  width: calc(100% - 95px);
}

.NewsWidget-newsTitle {
  max-width: 100%;
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #282e41;
}

.NewsWidget-newsPin {
  color: #e40521;
}

.NewsWidget-newsTitle span {
  white-space: nowrap;
}

.NewsWidget-newsDate {
  color: #282e41;
}
/* ==================== SANDCASTLE ==================== */

.Turnover {
  position: relative;
  height: 100%;
}

.Turnover .Widget-Title {
  text-align: right;
  padding-right: 100px;
}

.Turnover .Widget-Title::after {
  right: 0;
  left: 65%;
}

.Turnover-flex {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Turnover-LeftSide {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Turnover-RightSide {
  flex: 2;
  height: 100%;
}

.Sandcastle-Wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.Sandcastle--Overlay-Wrapper,
.Sandcastle--Background {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}

.Sandcastle--Overlay-Wrapper {
  overflow: hidden;
}

.colorIndicator {
  width: 11px;
  height: 11px;
  background: #3263aa;
  border-radius: 50%;
  display: inline-block;
}

.Turnover-Text {
  color: #282e41;
  font-size: 16px;
  font-weight: 600;
}

.Circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
}

.Circle-inner {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 6px solid #3263aa;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
}

.Circle-Amount {
  position: absolute;
  left: 50%;
}

/* ==================== ACCESS ==================== */

.Access .Widget-Title {
  margin-bottom: 35px;
}

.Access-Button {
  background: transparent;
  color: #ffffff;
  border: 0;
  font-weight: 600;
  margin-bottom: 25px;
  margin-left: 25px;
  margin-right: 25px;
  width: 90%;
  display: flex;
  align-items: center;
}

.AccessButton-Text {
  margin-left: 10px;
  font-size: 20px;
}

.Access-Data p {
  display: block;
  width: 100%;
}

.Access-Label {
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.Access-Data p:last-of-type {
  margin-bottom: 20px;
}

.Access-Field {
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 20px;
  text-overflow: ellipsis;
  max-width: 40%;
}

.Access-Back,
.Access-ShowAll {
  background: transparent;
  display: block;
  width: 70%;
  margin: 0 auto;
  text-align: center;
  color: #ffffff;
  padding: 10px 0;
  text-decoration: none;
  border-radius: 14px;
  border: 2px solid #ffffff;
  font-size: 16px;
  font-weight: 600;
}

/* ==================== WHO ==================== */

.Who-Flex {
  display: flex;
  flex-direction: column;
}

.Who-Top {
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Who-Top-Data {
  margin-left: 20px;
  font-size: 16px;
}

.Who-title {
  width: 100%;
  color: #282e41;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
}

.Who-Data-Link {
  width: 100%;
  color: #282e41;
  font-weight: 400;
  text-decoration: none;
  margin-bottom: 10px;
}

.Who-Data-Link svg {
  margin-right: 10px;
}

.Who-Br {
  margin: 15px 0 10px 0;
  height: 1px;
  border: 1px solid rgba(80, 97, 131, 0.3);
  background: transparent;
  color: transparent;
}

.Who-Search {
  width: 100%;
  border: 2px solid #ffffff;
  background: #efefef;
  border-radius: 40px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.21);
  display: flex;
  align-items: center;
}

.Who-Search-Icon {
  margin: 10px 5px 10px 20px;
  font-size: 22px;
  color: #282e41;
}

.Who-Search-Input {
  font-size: 16px;
  color: #282e41;
  padding: 15px 10px;
  width: 100%;
  border: 0;
  background: transparent;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}

/* ==================== DASHBOARD ==================== */

.Dashboard-Top {
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
}

.Dashboard-Title {
  font-weight: 600;
  font-size: 16px;
  color: #282e41;
}

.Dashboard-Slider {
  position: relative;
  -webkit-appearance: none;
  width: 100%;
  margin: 15px 0;
}

.Dashboard-Slider:focus {
  outline: none;
}

.Dashboard-Slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 7px;
  cursor: pointer;
  background: #282e41;
  border-radius: 11px;
}

.Dashboard-Slider::-webkit-slider-thumb {
  height: 21px;
  width: 200px;
  border-radius: 110px;
  background: #282e41;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}

.Dashboard-Slider::-moz-range-track {
  width: 100%;
  height: 7px;
  cursor: pointer;
  background: #282e41;
  border-radius: 11px;
}

.Dashboard-Slider::-moz-range-thumb {
  height: 21px;
  width: 200px;
  border-radius: 110px;
  background: #282e41;
  cursor: pointer;
}

.Dashboard-Slider::-ms-track {
  width: 100%;
  height: 7px;
  cursor: pointer;
  border-radius: 11px;
}

.Dashboard-Slider::-ms-thumb {
  width: 200px;
  border-radius: 110px;
  background: #282e41;
  cursor: pointer;
  height: 7px;
}

/* ==================== EDIT MODE ==================== */

.EditButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.EditButtons .EditMode-Button {
  margin-left: 10px;
}

.EditMode-Button {
  padding: 10px 45px;
  font-size: 13px;
  font-weight: 600;
  -webkit-appearance: none;
  border: 0;
  border-radius: 14px;
  background-color: #282e41;
  color: #ffffff;
  transition: all 250ms ease;
}

.EditMode-Button:hover,
.EditMode-Button:focus {
  cursor: pointer;
  background-color: #282e41;
}

.EditMode-Button svg {
  margin-right: 8px;
}

.EditMode-Button.Button--save,
.EditMode-Button--Green {
  background-color: #a6d35d;
}

.EditMode-Button.Button--cancel,
.EditMode-Button--Red {
  background-color: #e40521;
}

.AddWidget-Button {
  margin-left: 20px;
  margin-right: 40px;
  padding: 10px 45px;
  font-size: 13px;
  font-weight: 600;
  -webkit-appearance: none;
  border: 0;
  border-radius: 14px;
  background-color: #282e41;
  color: #ffffff;
  transition: all 250ms ease;
}

.AddWidget-Button:hover,
.AddWidget-Button:focus {
  cursor: pointer;
  background-color: #282e41;
}

.SupportMode {
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px 45px;
  font-size: 13px;
  font-weight: 600;
  -webkit-appearance: none;
  border: 0;
  border-radius: 14px;
  background-color: #282e41;
  color: #ffffff;
  transition: all 250ms ease;
  cursor: pointer;
}

.SupportMode.is-Active {
  background-color: #282e41;
}

.SupportMode.is-Active .SupportMode-Icon {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

/* ==================== TABLE ==================== */

.Table {
  width: 100%;
  max-width: 100%;
}

.Table-head,
.Table-body {
  max-width: 100%;
}

.Table-head,
.Table-body-row {
  display: flex;
  justify-content: space-between;
  transition: background 250ms ease;
  text-decoration: none;
}

.Table-head-field {
  background: #eaeef2;
  color: #282e41;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0 10px 30px;
  border-right: 1px solid rgba(80, 97, 131, 0.2);
}

.Table-head-field:last-of-type {
  border-right: 0;
}

.Table-head-field-orderable {
  padding: 10px 0 10px 8px;
  cursor: pointer;
  transition: transform 250ms ease;
}

.Table-head-field-orderable.is-Ordered .Table-head-field-ordered {
  transform: rotateZ(180deg);
}

.Table-body-row:nth-of-type(even) {
  background: rgba(234, 238, 242, 0.3);
}

.Table-body-row:hover {
  background: #eaeef2;
  cursor: pointer;
}

.Table-body-row.in-Edit {
  background: #eaeef2;
}

.Table-body-data {
  position: relative;
  font-size: 16px;
  padding: 12px 30px;
  color: #282e41;
  border-right: 1px solid rgba(80, 97, 131, 0.2);
  border-bottom: 1px solid rgba(80, 97, 131, 0.2);
}

.Usermanagement .Table-body-data:first-of-type {
  font-weight: 600;
  color: #282e41;
}

.Table-body-data:first-of-type,
.Table-body-data:last-of-type {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 250ms ease;
}

.Table-body-data:last-of-type:hover {
  color: #e40521;
}

.Usermanagement-Error {
  text-align: center;
  font-size: 20px;
  color: #e40521;
}

.Usermanagement-Error-Icon {
  margin-right: 10px;
}

.Table-body-data:last-of-type {
  border-right: 0;
}

.Table-body-data:last-of-type a {
  color: #282e41;
  text-decoration: none;
}

.Table-body-row:last-of-type .Table-body-data {
  border-bottom: 0;
}

.Table-body-data-field:first-of-type {
  margin-bottom: 5px;
}

.Table-body-data-field-icon {
  margin-right: 10px;
}

.Table-body-data-image {
  max-height: 100%;
  max-width: 100%;
}

.Table-Button {
  border: 0;
  width: 30px;
  height: 30px;
  margin: 0 3px;
  background: transparent;
  transition: all 500ms ease;
}

.Table-Button:hover {
  cursor: pointer;
  filter: brightness(75%);
}

.Table-Button--edit,
.Table-Button--save {
  color: #a6d35d;
}

.Table-Button--cancel,
.Table-Button--delete {
  color: #e40521;
}

.Table-data-editable {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0 30px;
  border: 0;
  font-size: 16px;
  color: #282e41;
}

.Table-body-data-value {
  width: 100%;
  border: 0;
  background: transparent;
  height: 100%;
  font-size: 16px;
  color: #282e41;
}

.Table-body-data-value:disabled {
  color: #282e41;
}

.Table-body-row.in-Edit .Table-data-editable,
.Table-body-row.in-Edit .Table-Button--save,
.Table-body-row.in-Edit .Table-Button--cancel {
  display: inline-block;
}

.Table-data-editable,
.Table-Button--save,
.Table-Button--cancel,
.Table-body-row.in-Edit .Table-Button--edit,
.Table-body-row.in-Edit .Table-Button--delete {
  display: none;
}

.redPlaceholder::placeholder {
  color: rgba(228, 5, 33, 0.7);
}

/* ==================== SPLITPANE ================== */

.Split-Panes {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
}

.Split-Panes .Split-Pane {
  padding: 10px;
}

.Split-Panes-Menu {
  width: 100%;
  display: grid;
  grid-template-columns: 200px auto;
}

.Split-Panes-Menu .Split-Pane-Menu {
  display: block;
  background-color: #d4d5d9;
}

.Split-Panes-Menu .Split-Pane-Menu-Nav {
  display: flex;
  flex-direction: column;
  position: fixed;
}

/* ==================== FORMAREA ==================== */

.FormArea {
  width: 100%;
  background: #eaeef2;
}

.FormArea-Top {
  width: 100%;
}

.FormArea-Title {
  margin: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #282e41;
  font-weight: 600;
  text-align: center;
}

.FormArea-Body {
  display: flex;
  justify-content: space-between;
}

.FormArea-Body-Tabs {
  width: 300px;
  display: flex;
  padding: 55px 0 20px 0;
  flex-direction: column;
}

.FormArea-Body-Tab {
  border: 0;
  width: 100%;
  font-size: 16px;
  color: #282e41;
  text-align: left;
  padding: 15px 25px;
  background: transparent;
  transition: all 250ms ease;
  cursor: pointer;
  text-decoration: none;
  width: 200px;
}

.FormArea-Body-Tab:hover,
.FormArea-Body-Tab:focus {
  color: #282e41;
  background: rgba(248, 249, 250, 0.6);
  outline: 0;
}

.FormArea-Body-Tab.is-Active {
  color: #ffffff;
  font-weight: 600;
  background: #272d40;
}

.FormArea-Body-Content {
  width: 100%;
  min-height: 100%;
  background: #f8f9fa;
}

.FormArea-Body-Content-Center {
  height: 100%;
  padding: 25px 0 25px 45px;
}

.FormArea-ContentBox {
  list-style-position: inside;
  padding: 30px;
  color: #272d40;
  background: #f4f4f5;
  position: relative;
  border-radius: 10px;
  height: auto;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0px 0px 8px lightgrey;
}

.FormArea-ContentBox > hr {
  border-top: 2px solid #272d40;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.FormArea-ContentBox > div,
.FormArea-ContentBox > hr {
  flex: 1 0 calc(50% - 30px);
  margin: 10px 15px;
}

.FormArea-ContentBox > .Input--fullWidth,
.FormArea-ContentBox > .Input--file,
.FormArea-ContentBox > .Editor {
  flex: 1 0 calc(100% - 30px);
}

.FormArea-ContentBox-title {
  width: 100%;
  color: #272d40;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 30px;
  border-bottom: 2px solid #272d40;
}

.FormArea-Form {
  display: none;
}

.FormArea-Form {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.FormArea-Form-Left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 40px);
  overflow: auto;
  /* height: calc(100vh - 500px); */
}

.FormArea-Form-Row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.Input-File {
  align-items: flex-start;
}

.FormArea-Form-Label {
  min-width: 250px;
  max-width: 250px;
  color: #282e41;
  font-size: 16px;
}

.FormArea-Form-Field {
  display: block;
  width: 100%;
  color: #282e41;
}

/* ==================== LOADER ==================== */

.Loader {
  width: 230px;
  height: 230px;
  transform-origin: 115px 115px;
  animation: 1.4s linear infinite loader-spin;
}

.Loader-Plane {
  fill: #282e41;
}

.Loader-Path {
  stroke: #282e41;
  animation: 1.4s ease-in-out infinite loader-path;
}

@keyframes loader-spin {
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader-path {
  0% {
    stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
  }
  50% {
    stroke-dasharray: 0, 450, 10, 30, 10, 30, 10, 30, 10;
  }
  100% {
    stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
  }
}

.LoginLoader {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 10px;
}

.LoginLoader div {
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.LoginLoader div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.LoginLoader div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.LoginLoader div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.LoginLoader div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

/* ==================== CLICKINPUT ==================== */

.ClickInput-Wrapper {
  position: relative;
}

.ClickInput-Error {
  color: #e40521;
  background: #ffffff;
  border: 1px solid #e40521;
  padding: 3px 19px;
  border-radius: 5px;
  position: absolute;
  top: -10px;
  transform: translate(-50%, -100%) scale(0.0000001);
  transform-origin: 50% 100%;
  left: -13px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  transition: transform 250ms ease;
}

.ClickInput-Error.is-Active {
  transform: translate(-50%, -100%) scale(1);
}

.ClickInput-Icon {
  position: absolute;
  left: 0;
  top: 50%;
  color: #a6d35d;
  transform: translate(-150%, -50%);
}

.ClickInput-Icon--Error {
  color: #e40521;
  left: -9px;
}

.ClickInput.has-Error:active,
.ClickInput.has-Error:focus {
  border-color: #e40521;
}

.ClickInput.has-Error::placeholder {
  color: rgba(228, 5, 33, 0.6);
}

.ClickInput-Wrapper.is-Edited .ClickInput--Bool-label,
.ClickInput-Wrapper.is-Edited .ClickInput {
  color: #a6d35d;
}

.ClickInput-Bool-Option {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
}

.ClickInput--Bool-label:hover,
.ClickInput--Bool:hover {
  cursor: pointer;
}

.ClickInput--Bool {
  width: auto;
  margin-right: 10px;
}

.Edited-Error-is-Active {
  left: -20px;
}

.DevPatch {
  position: absolute;
  text-align: center;
  top: 5px;
  margin-left: 25px;
  text-transform: uppercase;
  font-weight: 700;
  z-index: 1000;
}

.DevPatch-Inner {
  padding: 2px;
  color: #ffffff;
  background: rgb(224, 5, 30);
  border: 2px solid #ffffff;
  border-radius: 5px;
  width: 40px;
  font-size: 14px;
  margin: 0 auto;
}

iframe[style*='z-index: 2147483647'] {
  display: none;
}

.swal-content pre {
  display: block;
  background: #f1f1f1;
  padding: 10px;
  font-size: 12px;
  text-align: left;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.Indented {
  display: inline-block;
  background: #f1f1f1;
  padding: 4px 5px;
  border-radius: 3px;
  border: 1px solid #e1e1e1;
  font-weight: 700;
  color: #555;
}

.Changelog {
  position: fixed;
  z-index: 1000;
  background: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.00001);
  transform-origin: 150% 150%;
  height: 70%;
  width: 70%;
  border-radius: 15px;
  box-shadow: 0 0 36px rgba(22, 65, 148, 0.1);
  padding: 40px 60px;
  transition: transform ease 250ms;
}

.Changelog-Close {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: 0;
  color: #282e41;
  cursor: pointer;
}

.Changelog--is-Open {
  transform: translate(-50%, -50%) scale(1);
}

.Button-HttpTester {
  position: fixed;
  z-index: 99999;
  transform: rotate(90deg);
  padding: 3px 15px;
  background: rgb(224, 5, 30);
  border: 2px solid #ffffff;
  border-bottom: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  top: 15px;
  left: -15px;
  border-radius: 10px 10px 0 0;
}

/* ==================== DUTY ROSTER ==================== */
.DutyRoster {
  display: flex;
  position: relative;
}
.DutyRoster-calendar {
  flex: 1;
  position: relative;
  max-width: 100%;
}
.DutyRoster-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background: #282e41;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
}
.DutyRoster-prevMonth,
.DutyRoster-nextMonth {
  background: none;
  border: none;
  color: #ffffff;
}

.DutyRoster-week {
  display: flex;
  position: relative;
}

.DutyRoster-editWeek {
  display: flex;
  align-items: center;
  position: absolute;
  top: 15px;
  bottom: 15px;
  left: 0;
  transform: translateX(-100%);
  padding: 5px;
  border: none;
  background: linear-gradient(90deg, #282e41 0%, #316298 100%);
  border-radius: 5px 0 0 5px;
  font-size: 16px;
  color: #ffffff;
}

.DutyRoster-day {
  flex: 1;
  padding: 25px;
  position: relative;
  color: rgba(0, 0, 0, 0.4);
  border: none;
  outline: none;
  max-width: calc(100% / 7);
}

.DutyRoster-week + .DutyRoster-week {
  border-top: 1px solid #d9dde2;
}

.DutyRoster-week--heading .DutyRoster-day {
  text-align: center;
  background: #ebebeb;
  font-weight: 700;
  font-size: 16px;
  color: #000000;
}

.DutyRoster-calendar--week .DutyRoster-week--heading .DutyRoster-day {
  color: rgba(0, 0, 0, 0.65);
}

.DutyRoster-calendar--week .DutyRoster-week--heading .DutyRoster-day--disabled {
  background-color: #e3e3e3;
}

.DutyRoster-calendar--week .DutyRoster-week--body {
  min-height: 500px;
}

.DutyRoster-week--body .DutyRoster-day {
  background: #f2f2f2;
  min-height: 105px;
}

.DutyRoster-calendar--week .DutyRoster-week--body .DutyRoster-day {
  padding: 0;
}

.DutyRoster-calendar--week .DutyRoster-week--body .DutyRoster-day--disabled {
  background-color: #e3e3e3;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-style: italic;
  padding: 15px;
}

.DutyRoster-day + .DutyRoster-day {
  border-left: 1px solid #d9dde2;
}

.DutyRoster-week--heading .DutyRoster-day--green,
.DutyRoster-week--body .DutyRoster-day--green {
  background: #e0efc6;
}

.DutyRoster-week--heading .DutyRoster-day--yellow,
.DutyRoster-week--body .DutyRoster-day--yellow {
  background: #fff6c3;
}

.DutyRoster-week--heading .DutyRoster-day--red,
.DutyRoster-week--body .DutyRoster-day--red {
  background: #fce2e5;
}

.DutyRoster-week--heading .DutyRoster-day--today,
.DutyRoster-week--body .DutyRoster-day--today {
  box-shadow: inset 0 0 0 3px #e0051e;
  color: #e0051e;
}

.DutyRoster-dayNumber {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 16px;
}

.DutyRoster-dayOverviewLink {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #282e41;
  background: none;
  border: none;
  font-size: 16px;
}

.DutyRoster-dayDetails {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(100%, -50%);
  background: #f2f2f2;
  box-shadow: 13px 13px 16px rgba(0, 0, 0, 0.16);
  border: 3px solid #282e41;
  border-radius: 10px;
  padding: 30px;
  min-width: 440px;
  text-align: center;
  min-height: 750px;
  max-height: 750px;
  overflow: auto;
}

.DutyRoster-dayDetailsHeading {
  font-size: 21px;
  color: #282e41;
  margin-bottom: 30px;
}

.DutyRoster-dayDetailsEyployee {
  background: #ffffff;
  text-align: left;
  margin: 10px 0;
  border-radius: 9px;
  border: 1px solid #282e41;
  color: #282e41;
  padding: 10px 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.63);
}

.DutyRoster-employee {
  position: relative;
  background: #ffffff;
  text-align: left;
  margin: 10px;
  border-radius: 9px;
  border: 1px solid #282e41;
  padding: 10px;
  padding-right: 30px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.63);
  color: #282e41;
}

.DutyRoster-employee--isDisabled {
  opacity: 0.5;
}

.DutyRoster-employeeNotice--red {
  color: #e0051e;
}

.DutyRoster-employeeTime {
  font-size: 12px;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
}

.DutyRoster-employeeName {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DutyRoster-employeeRemove {
  position: absolute;
  top: 5px;
  right: 7px;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: #282e41;
}

.DutyRoster-employeeMoveHandle {
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 7px;
  border: none;
  background: none;
  padding: 0;
  margin: auto;
  font-size: 14px;
  height: 14px;
  width: 14px;
  color: #282e41;
}

.DutyRoster-employeeList {
  width: 250px;
  border: 1px solid #282e41;
}

.DutyRoster-employeeListHeader {
  text-align: left;
  padding: 15px;
  font-size: 16px;
  color: #ffffff;
  background: #282e41;
  line-height: 23px;
}

/* ==================== VACATION TABLE ==================== */

.DutyRoster-vacation {
  width: 585px;
  margin-left: 25px;
}
.VacationTable {
  width: 100%;
  border-spacing: 0;
}

.VacationTable td,
.VacationTable th {
  padding: 15px;
}

.VacationTable-head {
  text-align: left;
  padding: 15px;
  font-size: 16px;
  color: #ffffff;
  background: #282e41;
  line-height: 22px;
}

.VacationTable-sectionHead td {
  text-align: left;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
}

.VacationTable-sectionHead--grey td {
  background: #e4e7ea;
  color: rgba(0, 0, 0, 0.7);
}

.VacationTable-sectionHead--green td {
  background: #a6d35d;
  color: #ffffff;
}

.VacationTable-sectionHead--red td {
  background: #e40521;
  color: #ffffff;
}

.VacationTable-editVacation {
  color: #282e41;
}

/* ==================== REACT TABLE ==================== */
.ReactTable {
  border: none;
  width: 100%;
}

.ReactTable .rt-table {
  overflow-x: hidden;
}

.ReactTable .rt-thead.-header {
  box-shadow: none;
}

.ReactTable .rt-thead .rt-th {
  position: relative;
  background: #272d40;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding: 10px 15px;
  padding-left: 30px;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc,
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: none;
}

.ReactTable .rt-thead .rt-th.-sort-asc::after,
.ReactTable .rt-thead .rt-th.-sort-desc::after {
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  content: '\f078';
  font-family: 'Font Awesome 5 Pro';
  font-size: 16px;
  height: 15px;
  width: 15px;
}

.ReactTable .rt-thead .rt-th.-sort-desc::after {
  content: '\f077';
}

.ReactTable .rt-thead .rt-th:focus {
  outline: none;
}

.ReactTable .rt-tbody {
  overflow-x: hidden;
}

.ReactTable .rt-tbody .rt-tr {
  cursor: pointer;
  background: #dddde0;
}

.ReactTable .checkbox {
  position: relative !important;
  right: 15px !important;
}

.ReactTable .rt-tbody .rt-tr.-even {
  background: #f4f4f5;
}

.ReactTable .rt-tbody .rt-tr:hover {
  background: #eaeef2;
}

.ReactTable .rt-tbody .rt-tr.green {
  /* background: #a3dd82; */
}

.ReactTable .rt-tbody .rt-tr.green::before {
  content: '';
  border-right: 5px solid #a3dd82;
}

.ReactTable .rt-tbody .rt-tr.red::before {
  content: '';
  border-right: 5px solid #e0051d;
}

.ReactTable .rt-tbody .rt-tr.gray::before {
  content: '';
  border-right: 5px solid gray;
}
.ReactTable .rt-tbody .rt-tr.yellow::before {
  content: '';
  border-right: 5px solid yellow;
}

.ReactTable .rt-tbody .blocked .rt-td {
  /* color: gray; */
}

.ReactTable .rt-tbody .blocked:before {
  content: '';
  border-right: 5px solid #e0051e;
}

.ReactTable .rt-tbody .rt-td {
  /* position: relative; */
  font-size: 16px;
  padding: 12px 30px;
  color: #282e41;
}

.ReactTable .rt-noData {
  position: relative;
  top: auto;
  transform: none;
  left: auto;
  text-align: center;
  padding: 50px 20px;
}

/* ==================== NEWS ==================== */

.NewsContent {
  margin-top: -15px;
}

/* ==================== COMMENTS ==================== */

.CommentBox {
  width: 100%;
}

.Comment {
  padding-top: 30px;
  padding: 15px 40px;
  background: #ffffff;
  position: relative;
  border: 1px solid rgba(80, 97, 131, 0.5);
  border-radius: 10px;
  height: auto;
  width: 100%;
  margin-bottom: 20px;
}

.Comment-Head {
  display: flex;
  align-items: flex-end;
  color: #282e41;
  font-size: 17px;
}

.Comment-Name {
  color: #282e41;
  margin-right: 10px;
  text-decoration: none;
}

.Comment-Date {
  font-size: 13px;
}

.Comment-Content {
  margin: 15px 0;
}

textarea.Comment-Content {
  resize: vertical;
  height: 150px;
  font-size: 17px;
  margin: 15px 0 0 0;
}

.Comment-Actions-Opener {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 25px;
  color: #282e41;
  background: transparent;
  border: 0;
  padding: 0 10px;
}

.Comment-Actions {
  position: absolute;
  right: 10px;
  top: 35px;
  padding: 0;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid rgba(80, 97, 131, 0.5);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.Comment-Action {
  width: 100%;
  border: 0;
  font-size: 14px;
  padding: 0 10px;
  margin: 5px 0;
  color: #282e41;
  transition: all 250ms ease;
}

.Comment-Action:hover {
  color: #282e41;
}

.Comments-Pagination {
  list-style-type: none;
  display: inline-flex;
  margin: 10px 0;
  position: relative;
}

.Comments-Pagination li a {
  position: relative;
  margin: 0 2px;
  border-radius: 5px;
  color: #282e41;
  background: #ffffff;
  padding: 5px 10px;
  border: 1px solid rgba(80, 97, 131, 0.5);
  transition: all 250ms ease;
}

.Comments-Pagination li a:hover {
  cursor: pointer;
  background: #f8f9fa;
}

.Comments-Pagination li.selected a {
  background: #282e41;
  color: #ffffff;
}

/* ==================== COMMENTS COMPOSE ==================== */

.ComposeCommentBox {
  width: 100%;
}

.ComposeComment {
  padding: 15px 40px;
  background: #ffffff;
  position: relative;
  border: 1px solid rgba(80, 97, 131, 0.5);
  border-radius: 10px;
  height: auto;
  width: 100%;
  margin-bottom: 20px;
}

.ComposeComment-Text {
  transition: all 250ms ease;
  white-space: pre-line;
  resize: vertical;
  font-size: 15px;
  padding: 15px;
  height: 100px;
}

/* ==================== ATTACHEMENTS ==================== */

.Attachements-Files {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.Attachements-Button {
  border: 0;
  background: transparent;
  color: #282e41;
  text-align: left;
  margin-bottom: 10px;
}

.Attachements-Button:last-of-type {
  margin-bottom: 0;
}

.Attachements-Button-Icon {
  margin-right: 5px;
  font-size: 17px;
}

.Attachements-Button-Name {
  font-size: 17px;
}

/* ======================== FILE PREVIEW ======================= */
.FilePreview {
  display: flex;
  align-items: center;
  width: 100%;
  background: rgb(243, 244, 245);
  border-radius: 4px;
  padding: 5px 15px 5px 5px;
  margin: 10px 0;
}

.FilePreview--marked {
  opacity: 0.5;
}

.FilePreview-title {
  font-size: 16px;
  color: #111111;
  text-align: left;
  font-weight: normal;
  flex: 1;
  padding: 0 10px;
  text-overflow: ellipsis;
}

.FilePreview-suffix {
  color: #9c9c9c;
}

.FilePreview-size {
  display: block;
  color: #9c9c9c;
  font-size: 12px;
}

.FilePreview-download {
  margin-left: 30px;
}

/* ==================== File Download ==================== */

.File-Download-Files {
  display: flex;
  flex-wrap: wrap;
}

.File-Download-Button {
  -webkit-appearance: none;
  color: #282e41;
  border: 0;
  margin-right: 20px;
  font-size: 17px;
  background: #eaeef2;
  border-radius: 5px;
  padding: 5px 10px;
  transition: all 250ms ease;
}

.File-Download-Button:hover {
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
}

.File-Download-Button:last-of-type {
  margin-right: 0;
}

.File-Download-Icon {
  margin-right: 5px;
}

/* ==================== Opening Hour Item ==================== */

.OpeningHourItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.OpeningHourItem-Label {
  min-width: 150px;
  max-width: 150px;
  color: #506183;
  font-size: 16px;
}

.OpeningHourItem .Input + .Input {
  margin-left: 30px;
}

.Error {
  color: #ed0028;
}

.Map-Wrapper {
  width: 100%;
  height: 361px;
}

.Map-Container {
  width: 100%;
  height: 100%;
}

.Map-MarkerWrapper {
  position: relative;
}

.Map-MarkerImage:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.Map-MarkerOverlay .Map-MarkerButtons {
  display: flex;
  gap: 10px;
}

.Map-MarkerOverlay .Map-MarkerButton {
  text-decoration: none;
  -webkit-appearance: none;
  border: none;
  background: #272d40;
  color: #ffffff;
  font-size: 12px;
  padding: 3px;
  border-radius: 5px;
}

.Toolbar-Filter {
  display: flex;
  gap: 10px;
  align-items: flex-end;
}

.SearchDropdown {
  flex: 1;
}

.SearchDropdown > div {
  width: 100%;
  max-width: 100%;
}

.SearchDropdown .Dropdown__indicator svg,
.SearchDropdown .Dropdown__indicator-separator {
  display: flex;
}

.SearchDropdown .Dropdown-Caret {
  display: none;
}

/* ======================== SWAL ======================= */

.swal-footer .swal-button {
  background: #272d40;
}

.swal-footer .swal-button:hover {
  background: #d4d5d9;
  color: #000;
}

/* ======================== AvailabilityChecker ======================= */

.AvailabilityChecker {
  max-width: calc(50% - 30px);
}

.AC-Input {
  display: block;
  width: 100%;
  padding: 10px;
  font-weight: 400;
  font-size: 16px;
  background: white;
  border: 1px solid #9fb4cb;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  margin-top: 5px;
}

.AC-Table {
  margin-right: 20px;
  /* margin-bottom: -15px; */
}

.AC-Table-head,
.AC-Table-body {
  max-width: 100%;
}

.AC-Table-head,
.AC-Table-body-row {
  height: 40px;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
}

.AC-Table-head-field {
}
.AC-Table-body-label {
  width: 70px;
}

.AC-Table-body-data {
}

.AC-Date-wrapper {
  border-top: 1px solid #9fb4cb;
  padding-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* ======================== OfficeHours ======================= */

.OfficeHours {
  max-width: calc(50% - 30px);
}

.OH-Input {
  display: block;
  width: 100%;
  padding: 10px;
  font-weight: 400;
  font-size: 16px;
  background: white;
  border: 1px solid #9fb4cb;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  margin-top: 5px;
}

.OH-Table {
  margin-right: 20px;
  /* margin-bottom: -15px; */
}

.OH-Table-head,
.OH-Table-body {
  max-width: 100%;
}

.OH-Table-head,
.OH-Table-body-row {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.OH-Table-head-field {
  text-align: center;
  width: calc((100% - 100px) / 5);
}

.OH-Table-body-label {
  width: 90px;
}

.OH-Table-body-data {
  text-align: center;
  font-size: 0.8em;
  width: calc((100% - 100px) / 5);
  padding: 3px;
  border-radius: 3px;
  border: 1px solid #9fb4cb;
  cursor: pointer;
}

.OH-Table-body-data:hover {
  background-color: #9fb4cb55;
}

.OH-Table-body-data.filled {
  background-color: #9fb4cb55;
}

.OH-Date-wrapper {
  border-top: 1px solid #9fb4cb;
  padding-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* ======================== TimeRange ======================= */

.TimeRange {
  position: relative;
}

.TimeRange .TimeRange-button {
  overflow: hidden;
}

.TimeRange .TimeRange-picker {
  z-index: 100;
  position: absolute;
  width: 200px;
  top: 150%;
  left: -5px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #9fb4cb;
  border-radius: 5px;
  box-shadow: 1px 1px 3px grey;
}

.TR-inputs {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.TR-inputs input {
  margin: 3px;
}

/* ======================== CapacityForInsured ======================= */

.CapacityForInsured {
  max-width: calc(50% - 30px);
}

.CI-Input {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 20px 10px 20px 10px;
  font-weight: 400;
  font-size: 16px;
  background: white;
  border: 1px solid #9fb4cb;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  margin-top: 5px;
}

/* ======================== Disorders ======================= */

.Disorders-Input {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  padding: 15px;
  font-weight: 400;
  font-size: 16px;
  background: white;
  border: 1px solid #9fb4cb;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  margin-top: 5px;
}

.Disorders-Input .Dropdown {
  width: 100%;
}

/* ======================== AmbPsychForm ======================= */

.FormArea-ContentBox .Absent-Info {
  display: flex;
  justify-content: center;
  padding: 15px;
}

.FormArea-ContentBox .Absent-Info .Label {
  padding: 13px 20px;
  font-weight: 400;
  font-size: 16px;
  background: #fff;
  border: 1px solid #9fb4cb;
  border-radius: 5px;
  justify-content: center;
  color: #e40521;
}

.FormArea-ContentBox .Absent-Info .Icon {
  margin-left: 15px;
  color: #e40521;
}

/* ======================== Imprint ======================= */
.Impressum {
  padding: 20px;
  max-width: 800px;
}
.Impressum p {
  margin-bottom: 12px;
}

.Impressum h2 {
  color: #000;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
}

.Impressum h3 {
  color: #000;
  font-weight: normal;
  font-size: 16px;
  margin: 0;
}

/* ======================== Datenschutz ======================= */
.Datenschutz {
  padding: 20px;
  max-width: 800px;
}
.Datenschutz ul {
  margin-left: 20px;
}
.Datenschutz li {
  margin-bottom: 16px;
}

/* ======================== TransferDB ======================= */
.pulse-button {
  box-shadow: 0 0 0 0 #272d406c;
  animation: pulse 2.5s infinite;
}
.pulse-button:hover {
  animation: none;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 20px #272d406c;
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 #272d406c;
  }
}

.Button-bigger {
  font-size: 28px;
}

.Radio-Input {
  margin-top: 10px;
}
.Radio-title {
  padding-left: 10px;
  color: #282e41;
  font-size: 16px;
  min-width: 250px;
  max-width: 100%;
}

/*  */

.fieldset {
  width: 438px;
  height: auto;
  border-radius: 8px;
  background-color: #efefef;
  border: 0px;
  padding: 10px 10px;
}
.fieldset legend {
  margin: 5px 0px;
}
.fieldset .fieldset-radio {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 4px;
}

.Toolbar .fieldset input {
  margin: 0.4rem;
}
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}
.tab button:hover {
  background-color: #ddd;
}
.tab button.active {
  background-color: #ccc;
}

/* ======================== Locked-Icon   ======================= */
.locked-icon {
  position: relative !important;
  right: 7px;
  color: #e1051e;
  bottom: 2px;
}
/* ======================== Tooltip-filter  ======================= */
.tooltip-filter {
  display: inline-block;
  position: absolute;
  border-bottom: 1px dotted #666;
  text-align: left;
}

.tooltip-filter h3 {
  margin: 12px 0;
}

.tooltip-filter .tooltip-text-filter {
  min-width: 200px;
  max-width: 400px;
  top: -20px;
  left: 50%;
  transform: translate(-30%, -100%);
  padding: 10px 20px;
  color: #ffffff;
  background-color: #282e41;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border-radius: 8px;
  position: absolute;
  z-index: 5;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  display: none;
  left: -100px;
}

.tooltip-filter:hover .tooltip-text-filter {
  display: block;
}

.tooltip-filter .tooltip-text-filter i {
  position: absolute;
  top: 100%;
  left: 80%;
  margin-left: -15px;
  width: 30px;
  height: 15px;
  overflow: hidden;
}

.tooltip-filter .tooltip-text-filter i::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #282e41;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

.filter-items {
  list-style: none;
  display: flex;
  align-items: center;
}
.check-filter {
  color: green;
  font-size: 1.2em;
  padding: 0 5px;
  margin: 2px;
}
.ex-filter {
  color: red;
  font-weight: 700;
  font-size: 1.9em;
  padding: 0 5px;
  margin: 2px;
}
/* ======================== EmailArea  ======================= */
.emailArea {
  display: flex;
  flex-direction: row;
}
/* ======================== Tooltip-text-emails  ======================= */
.tooltip-emails {
  background: #efefef;
  border-radius: 4px;
  margin: 0 0 10px;
  text-align: left;
  width: 400px;
  z-index: 100;
  padding: 5px;
}
.tooltip-email-title {
  margin: 5px 3px;
  font-weight: 500;
}
.tooltip-email-view {
  background: #fff;
  border-radius: 4px;
  height: 100px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 10px;
}
.tooltip-emails .tooltip-text-emails {
  min-width: 200px;
  max-width: 400px;
  top: -20px;
  left: 50%;
  transform: translate(-30%, -100%);
  padding: 10px 20px;
  color: #ffffff;
  background-color: #e4002b;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border-radius: 8px;
  position: absolute;
  z-index: 5;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  display: none;
  left: -100px;
}
.tooltip-email-buttons {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}
/* ======================== ExportStatistic  ======================= */
.exportStatistic-content-area {
  margin: 20px;
}
.exportStatistic-button-area {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 20px;
}

/* ========================= AutofillCheckout =====================  */
.AutofillCheckout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  gap: 20px;
}
